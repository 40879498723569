import revive_payload_client_yd74LGfF95 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.12.14_encoding@0.1.13_eslint@9.4.0_ioredis@5_gwdi2afgm7q3ft7snciiuorrv4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_hTsYI3QPrK from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.12.14_encoding@0.1.13_eslint@9.4.0_ioredis@5_gwdi2afgm7q3ft7snciiuorrv4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_7Vkp9W5ohk from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.12.14_encoding@0.1.13_eslint@9.4.0_ioredis@5_gwdi2afgm7q3ft7snciiuorrv4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import clerk_lzqarMDdN6 from "/vercel/path0/node_modules/.pnpm/vue-clerk@0.6.9_@clerk+backend@1.11.1_react@18.3.1__react@18.3.1_vue@3.5.1_typescript@5.4.5_/node_modules/vue-clerk/dist/nuxt/runtime/plugins/clerk.js";
import payload_client_Af6pkXW3sT from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.12.14_encoding@0.1.13_eslint@9.4.0_ioredis@5_gwdi2afgm7q3ft7snciiuorrv4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_dv9qTiGuhy from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.12.14_encoding@0.1.13_eslint@9.4.0_ioredis@5_gwdi2afgm7q3ft7snciiuorrv4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_oV3JVhjXH7 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.12.14_encoding@0.1.13_eslint@9.4.0_ioredis@5_gwdi2afgm7q3ft7snciiuorrv4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_rURH8rPuH0 from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.12.14_encoding@0.1.13_eslint@9.4.0_ioredis@5_gwdi2afgm7q3ft7snciiuorrv4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import components_plugin_KR1HBZs4kY from "/vercel/path0/packages/application/.nuxt/components.plugin.mjs";
import prefetch_client_Be0AkBt5wc from "/vercel/path0/node_modules/.pnpm/nuxt@3.13.1_@parcel+watcher@2.4.1_@types+node@20.12.14_encoding@0.1.13_eslint@9.4.0_ioredis@5_gwdi2afgm7q3ft7snciiuorrv4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import slideovers_szgysy6BTf from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.0_axios@1.7.4_change-case@4.1.2_magicast@0.3.5_rollup@4.21.2_vite@5.4.4_@types+_mdorhqblao74jvwvh2ghs4hpfu/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_H1ZYXlFN7i from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.0_axios@1.7.4_change-case@4.1.2_magicast@0.3.5_rollup@4.21.2_vite@5.4.4_@types+_mdorhqblao74jvwvh2ghs4hpfu/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_Ns3tsn9pol from "/vercel/path0/node_modules/.pnpm/@nuxt+ui@2.18.0_axios@1.7.4_change-case@4.1.2_magicast@0.3.5_rollup@4.21.2_vite@5.4.4_@types+_mdorhqblao74jvwvh2ghs4hpfu/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_cAxDbYXIPn from "/vercel/path0/node_modules/.pnpm/@nuxtjs+color-mode@3.4.4_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_n2SQK0XV5Z from "/vercel/path0/node_modules/.pnpm/@nuxt+icon@1.5.1_magicast@0.3.5_rollup@4.21.2_vite@5.4.4_@types+node@20.12.14_terser@5.32.0___biusjdciklzevfxa37qlj5ikfe/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import unocss_MzCDxu9LMj from "/vercel/path0/packages/application/.nuxt/unocss.mjs";
import sentry_client_surB6E857o from "/vercel/path0/node_modules/.pnpm/@sentry+nuxt@8.26.0_@opentelemetry+api@1.9.0_@opentelemetry+core@1.26.0_@opentelemetry+api@1._gi34hxrrtfwdo6g6ftzquzrbii/node_modules/@sentry/nuxt/build/module/runtime/plugins/sentry.client.js";
import sentry_client_config_o34nk2sJbg from "/vercel/path0/packages/application/.nuxt/sentry-client-config.mjs";
import plugin_client_wHtNg5Smv1 from "/vercel/path0/node_modules/.pnpm/nuxt-gtag@3.0.1_magicast@0.3.5_rollup@4.21.2_webpack-sources@3.2.3/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import plugin_prod_client_mLSjFAmje3 from "/vercel/path0/node_modules/.pnpm/nuxt-monaco-editor@1.2.9_magicast@0.3.5_monaco-editor@0.49.0_rollup@4.21.2_vite@5.4.4_@types+_czk6mp7ekwwblgbtc5mi5pxmla/node_modules/nuxt-monaco-editor/dist/runtime/plugin-prod.client.mjs";
import plugin_client_6mTZ2Lkk0O from "/vercel/path0/node_modules/.pnpm/@samk-dev+nuxt-vcalendar@1.0.3_magicast@0.3.5_rollup@4.21.2_vue@3.5.1_typescript@5.4.5__webpack-sources@3.2.3/node_modules/@samk-dev/nuxt-vcalendar/dist/runtime/plugin.client.mjs";
import plugin_ziYcKwnJdq from "/vercel/path0/node_modules/.pnpm/@formkit+auto-animate@0.8.2/node_modules/@formkit/auto-animate/nuxt/runtime/plugin.mjs";
import _0_force_logout_per_revision_TeCF2tpkEo from "/vercel/path0/packages/application/plugins/0.force-logout-per-revision.ts";
import feature_flags_3X7A4FWWIa from "/vercel/path0/packages/application/plugins/feature-flags.ts";
import input_autowidth_bJoGoxmQ7Q from "/vercel/path0/packages/application/plugins/input-autowidth.ts";
import liveblocks_ch8igrfBKq from "/vercel/path0/packages/application/plugins/liveblocks.ts";
import test_id_TLE30LqioL from "/vercel/path0/packages/application/plugins/test-id.ts";
export default [
  revive_payload_client_yd74LGfF95,
  unhead_hTsYI3QPrK,
  router_7Vkp9W5ohk,
  clerk_lzqarMDdN6,
  payload_client_Af6pkXW3sT,
  navigation_repaint_client_dv9qTiGuhy,
  check_outdated_build_client_oV3JVhjXH7,
  chunk_reload_client_rURH8rPuH0,
  components_plugin_KR1HBZs4kY,
  prefetch_client_Be0AkBt5wc,
  slideovers_szgysy6BTf,
  modals_H1ZYXlFN7i,
  colors_Ns3tsn9pol,
  plugin_client_cAxDbYXIPn,
  plugin_n2SQK0XV5Z,
  unocss_MzCDxu9LMj,
  sentry_client_surB6E857o,
  sentry_client_config_o34nk2sJbg,
  plugin_client_wHtNg5Smv1,
  plugin_prod_client_mLSjFAmje3,
  plugin_client_6mTZ2Lkk0O,
  plugin_ziYcKwnJdq,
  _0_force_logout_per_revision_TeCF2tpkEo,
  feature_flags_3X7A4FWWIa,
  input_autowidth_bJoGoxmQ7Q,
  liveblocks_ch8igrfBKq,
  test_id_TLE30LqioL
]