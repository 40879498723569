import { useUser } from 'vue-clerk'
import type { UserResource as ClerkUser } from '@clerk/types'
import type { User } from 'tela-api/src/database/schemas/user'
import * as Sentry from '@sentry/nuxt'

export function useUserData() {
    const userData = useState<ClerkUser | null>('user-data', () => null)

    if (import.meta.client) {
        try {
            const { user } = useUser()

            watchUnique(user, (newUser) => {
                if (!newUser) {
                    userData.value = null
                    Sentry.setUser(null)
                }
                else {
                    userData.value = {
                        ...newUser,
                        createOrganizationsLimit: 1,
                        imageUrl: newUser.hasImage ? newUser.imageUrl : (newUser?.externalAccounts?.find(({ imageUrl }) => !!imageUrl)?.imageUrl ?? newUser.imageUrl),
                    } as unknown as ClerkUser

                    Sentry.setUser({
                        id: newUser.id,
                        email: newUser.primaryEmailAddress?.emailAddress ?? '',
                        username: newUser.fullName ?? '',
                        ip_address: '{{auto}}',
                    })
                }
            }, { immediate: true, key: 'refresh-user-data' })
        }
        catch (e) {
        }
    }

    async function fetchUserById(userId: string) {
        const { api } = useAPI()
        const fetchResponse = await api().user[userId].get()

        if (fetchResponse.error)
            throw fetchResponse.error

        return {
            email: fetchResponse.data.primaryEmailAddress?.emailAddress ?? '',
            name: fetchResponse.data.fullName,
            avatar_url: fetchResponse.data.imageUrl,
            createdAt: new Date(fetchResponse.data.createdAt),
            updatedAt: new Date(fetchResponse.data.updatedAt),
        } as User
    }

    return {
        fetchUserById,
        user: userData,
    }
}
